/* eslint-disable jsx-a11y/anchor-is-valid */
import React ,{useMemo,useEffect,useState}from "react";
import { Field } from "formik";
import {SetCookies,RemoveCookies,GetCookies} from '../../../../../_helpers/Cookies'
export const Color = ({formik,naturalData,setFancy,fancy,naturalFancyData,language}) => {
    const [whiteColor,setWhiteColor] =useState(naturalData.length !== 0 && naturalData[0].color !== null ? naturalData[0].color : [])
 
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)

    
    const newWhiteColorData =whiteColor.length!==0 ? whiteColor.split(',').map((value,index) => {
        return {
            id: index,
            wcolor:value
        }
    }):null
    // const IntensityData = ([
    //     {
    //         id: "int_1",
    //         value:"FAINT",
    //         intensity:"FAINT"
    //     },
    //     {
    //         id: "int_2",
    //         value:"VERY LIGHT",
    //         intensity:"VERY LIGHT"
    //     },
    //     {
    //         id: "int_3",
    //         value:"LIGHT",
    //         intensity:"LIGHT"
    //     },
    //     {
    //         id: "int_4",
    //         value:"FANCY LIGHT",
    //         intensity:"FANCY LIGHT"
    //     },
    //     {
    //         id: "int_5",
    //         value:"FANCY",
    //         intensity:"FANCY"
    //     },
    //     {
    //         id: "int_6",
    //         value:"FANCY DARK",
    //         intensity:"FANCY DARK"
    //     },
    //     {
    //         id: "int_7",
    //         value:"FANCY INTENSE",
    //         intensity:"FANCY INTENSE"
    //     },
    //     {
    //         id: "int_8",
    //         value:"FANCY VIVID",
    //         intensity:"FANCY VIVID"
    //     },
    //     {
    //         id: "int_9",
    //         value:"FANCY DEEP",
    //         intensity:"FANCY DEEP"
    //     }
    // ]);
    // const ColorData = ([
    //     {
    //         id: 1,
    //         value:"YELLOW",
    //         color:"YELLOW"
    //     },
    //     {
    //         id: 2,
    //         value:"PINK",
    //         color:"PINK"
    //     },
    //     {
    //         id: 3,
    //         value:"BLUE",
    //         color:"BLUE"
    //     },
    //     {
    //         id: 4,
    //         value:"RED",
    //         color:"RED"
    //     },
    //     {
    //         id: 5,
    //         value:"GREEN",
    //         color:"GREEN"
    //     },
    //     {
    //         id: 6,
    //         value:"PURPLE",
    //         color:"PURPLE"
    //     },
    //     {
    //         id: 7,
    //         value:"ORANGE",
    //         color:"ORANGE"
    //     },
    //     {
    //         id: 8,
    //         value:"VIOLET",
    //         color:"VIOLET"
    //     },
    //     {
    //         id: 10,
    //         value:"GREY",
    //         color:"GREY"
    //     },
    //     {
    //         id: 11,
    //         value:"BLACK",
    //         color:"BLACK"
    //     },
    //     {
    //         id: 12,
    //         value:"BROWN",
    //         color:"BROWN"
    //     },
    //     {
    //         id: 13,
    //         value:"Champagne",
    //         color:"Champagne"
    //     },
    //     {
    //         id: 14,
    //         value:"Cognac",
    //         color:"Cognac"
    //     },
    //     {
    //         id: 15,
    //         value:"Chameleon",
    //         color:"Chameleon"
    //     },
    //     {
    //         id: 16,
    //         value:"WHITE",
    //         color:"WHITE"
    //     },
    //     {
    //         id: 17,
    //         value:"Other",
    //         color:"Other"
    //     }
    // ]);
    // const OvertoneData = ([
    //     {
    //         id: 1,
    //         value:"YELLOW",
    //         overtone:"YELLOW"
    //     },
    //     {
    //         id: 2,
    //         value:"Yellowish",
    //         overtone:"Yellowish"
    //     },
    //     {
    //         id: 3,
    //         value:"Pink",
    //         overtone:"Pink"
    //     },
    //     {
    //         id: 4,
    //         value:"Pinkish",
    //         overtone:"Pinkish"
    //     },
    //     {
    //         id: 5,
    //         value:"Blue",
    //         overtone:"Blue"
    //     },
    //     {
    //         id: 6,
    //         value:"Blueish",
    //         overtone:"Blueish"
    //     },
    //     {
    //         id: 7,
    //         value:"Red",
    //         overtone:"Red"
    //     },
    //     {
    //         id: 8,
    //         value:"Reddish",
    //         overtone:"Reddish"
    //     },
    //     {
    //         id: 9,
    //         value:"Green",
    //         overtone:"Green"
    //     },
    //     {
    //         id: 10,
    //         value:"Greenish",
    //         overtone:"Greenish"
    //     },
    //     {
    //         id: 11,
    //         value:"Purple",
    //         overtone:"Purple"
    //     },
    //     {
    //         id: 12,
    //         value:"Purplish",
    //         overtone:"Purplish"
    //     },
    //     {
    //         id: 13,
    //         value:"Orange",
    //         overtone:"Orange"
    //     },
    //     {
    //         id: 14,
    //         value:"Orangy",
    //         overtone:"Orangy"
    //     },
    //     {
    //         id: 15,
    //         value:"VIOLET",
    //         overtone:"VIOLET"
    //     },
    //     {
    //         id: 16,
    //         value:"Violetish",
    //         overtone:"Violetish"
    //     },
    //     {
    //         id: 17,
    //         value:"Gray",
    //         overtone:"Gray"
    //     },
    //     {
    //         id: 18,
    //         value:"Grayish",
    //         overtone:"Grayish"
    //     },
    //     {
    //         id: 19,
    //         value:"Black",
    //         overtone:"Black"
    //     },
    //     {
    //         id: 20,
    //         value:"Brown",
    //         overtone:"Brown"
    //     },
    //     {
    //         id: 21,
    //         value:"BROWNISH",
    //         overtone:"BROWNISH"
    //     },
    //     {
    //         id: 22,
    //         value:"Champagne",
    //         overtone:"Champagne"
    //     },
    //     {
    //         id: 23,
    //         value:"Cognac",
    //         overtone:"Cognac"
    //     },
    //     {
    //         id: 24,
    //         value:"Chameleon",
    //         overtone:"Chameleon"
    //     },
    //     {
    //         id: 25,
    //         value:"WHITE",
    //         overtone:"WHITE"
    //     }
    //     ,
    //     {
    //         id: 26,
    //         value:"Other",
    //         overtone:"Other"
    //     }
    // ]);
    // const whitecolorData = ([
    //     {
    //         id: 0,
    //         wcolor:"D"
    //     },
    //     {
    //         id: 1,
    //         wcolor:"E"
    //     },
    //     {
    //         id: 2,
    //         wcolor:"F"
    //     },
    //     {
    //         id: 3,
    //         wcolor:"G"
    //     },
    //     {
    //         id: 4,
    //         wcolor:"H"
    //     },
    //     {
    //         id: 5,
    //         wcolor:"I"
    //     },
    //     {
    //         id: 6,
    //         wcolor:"J"
    //     },
    //     {
    //         id: 7,
    //         wcolor:"K"
    //     },
    //     {
    //         id: 8,
    //         wcolor:"L"
    //     },
    //     {
    //         id: 9,
    //         wcolor:"M"
    //     },
    //     {
    //         id: 10,
    //         wcolor:"N"
    //     },
    //     {
    //         id: 11,
    //         wcolor:"OP"
    //     },
    //     {
    //         id: 12,
    //         wcolor:"QR"
    //     },
    //     {
    //         id: 13,
    //         wcolor:"ST"
    //     },
    //     {
    //         id: 14,
    //         wcolor:"UV"
    //     },
    //     {
    //         id: 15,
    //         wcolor:"WX"
    //     },
    //     {
    //         id: 16,
    //         wcolor:"YZ"
    //     }
    // ]);
    const ResetColor = () => {
        // formik.setFieldValue("Fancy_Color",[])
        // formik.setFieldValue("Fancy_Color_Overtone",[])
        // formik.setFieldValue("Fancy_Color_Intensity",[])
    }

    return (
        <>
            <div className="row mt-5 pb-8 border-bottom">
                <div className="col-lg-2">
                    <p className="font-size-h6 mb-0">{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR}</p>
                        <span className="small font-weight-bold ml-2">
                            {naturalData && naturalData.length && naturalData[0].status === 1?<span className={`${!fancy ? "text-primary" : ""} cursor-pointer`} onClick={()=>setFancy(false)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_WHITE}</span>:""}
                            {naturalData && naturalData.length && naturalData[0].status === 1 && naturalFancyData && naturalFancyData.length && naturalFancyData[0].status === 1?<>/</>:""}
                            {naturalFancyData && naturalFancyData.length && naturalFancyData[0].status === 1?<span className={`${fancy ? "text-primary" : ""} cursor-pointer`} onClick={()=>setFancy(true)}>{language.CCMODE_THEME1_SEARCHDIAMOND_COLOR_FANCY}</span>:""}
                        </span>
                </div>
                <div className={`col-lg-10`}>
                    {newWhiteColorData ? newWhiteColorData.map((item) => (
                        <label key={item.id} className="search_btn_check">
                            <Field type="checkbox" name="Color" id={`${item.wcolor}_wcolor`} value={item.wcolor}/>
                            <div className="finishBox selectBox">
                                <span>{item.wcolor}</span>
                            </div>
                        </label>
                    )):null}
                </div>
                {/* <div className={`col-lg-10 color  `} >
                    <div className="row">
                        <div className="col-md-12 mb-3 font-weight-bold">
                            Intensity
                        </div>
                        <div className="col-md-12">
                            {IntensityData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color_Intensity" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.intensity}</span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mb-3 font-weight-bold">
                            Overtone
                        </div>
                        <div className="col-md-12">
                            {OvertoneData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color_Overtone" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.overtone}</span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12 mb-3 font-weight-bold">
                            Color
                        </div>
                        <div className="col-md-12">
                            {ColorData.map((item) => (
                                <label key={item.id} className="search_btn_check">
                                    <Field type="checkbox" name="Fancy_Color" id={`${item.id}`} value={item.value}/>
                                    <div className="fancyBox selectBox">
                                        <span>{item.color}</span>
                                    </div>
                                </label>
                            ))}
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
};
