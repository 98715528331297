import React, { useMemo, useState,useEffect } from "react";
import { useTable, useGlobalFilter,  usePagination, } from "react-table";
import { useLocation, useHistory } from "react-router-dom";
// import GlobleFilter from "../../globalFilter/GlobleFilter";
import {  FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight,FaAward,FaLongArrowAltLeft } from "react-icons/fa";
import { BsFillGridFill } from "react-icons/bs";
import {  OverlayTrigger, Tooltip } from "react-bootstrap";
import { makeStyles, Snackbar, IconButton, SnackbarContent} from "@material-ui/core";
import PropTypes from "prop-types";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
// import axios from "axios";
// import {IUD_SHORTLIST} from "./../../../env_config"
import {PopUp} from "../../popup/popUp";
import {  useSelector } from "react-redux";
import {VideoPopup} from "../../popup/videoPopup";
import {ImagePopup} from "../../popup/imagePopup";
// import { useContext } from "react";
// import DiamondContext from "../searchDiamond/SearchDiamondContext";
// import { GetDiamondDB } from "../../../_helpers/IndexedDB";
import axios from "axios"
import { NODE_API_URL } from "../../../../env_config";
import SortingTheme1 from "../grid/SortingTheme1";
import CertificateLink from "../../CertificateLink";
const CancelToken = axios.CancelToken;
let cancel;
let searchcancel;
export const SearchResultTheme1 = (props) => {
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [showWithoutModel, setShowWithoutModel] = React.useState(false);
    const history = useHistory();
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const natStoneCount = useMemo(()=> localStorage.getItem("stoneCount"),[]);
    const [sorting,setSorting] = useState("")
//   console.log("ssssss",natStoneCount)
    //-------------------------------------------------------------------------
    const {
        hideCertificateNumber,
        hideEstimatedShippingDays,
        hidePrice,
      } = useSelector((state) => {
        return state.CcModeSettingReducer;
      });    
    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };

    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    const classes = useStyles();
    const [successOpen, setSuccessOpen] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon
                            className={clsx(classes.icon, classes.iconVariant)}
                        />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };

    function handleClose(event, reason) {
        setOpen(false);
        // successOpen(false);
        setCustomizeTable(false)
        setShowWithoutModel(false);
    }
    function withoutSession() {
        setOpen(true);
    }
   
    function ViewDetail(d){
        history.push({ pathname: "/details", state: {
            StockID:d.STOCK_ID,
            diamond_type:d.COLOR?d.diamond_type:d.diamond_type + "F"
        } });
    }
   

    //-------------------------------------------------------------------------


    const location = useLocation();
    // console.log(location.state)
    const [data,setData] = useState([])
    const [stoneParams,setStoneParams] = useState()
    const [count, setCount] = React.useState(0);
    const [loader,setLoader] = useState(true)
    // console.log(data,"datadata")
    React.useEffect(() => {
        if(location.state.diamondparams){
            if(location.state.diamondparams.diamond_type === "N"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...location.state.diamondparams,currency:localStorage.getItem("user_currency")},
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    // setinfiniteLoader(false)
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...location.state.diamondparams,
                                diamond_type:location.state.diamondparams["fancy_color_diamond"] === "YES"?location.state.diamondparams.diamond_type+"F":location.state.diamondparams.diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        

                    }
                }).catch(err => {
                    
                })
            }
            if(location.state.diamondparams.diamond_type === "L"){
                if (searchcancel) {
                    searchcancel();
                  }
          
                  // Create a new cancel token for the current request
                  const newCancelTokenSearch = new CancelToken(function executor(c) {
                    searchcancel = c;
                  });
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {...location.state.diamondparams,currency:localStorage.getItem("user_currency")},
                    cancelToken:newCancelTokenSearch
                }).then(res => {
                    // setinfiniteLoader(false)
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        axios({
                            method: "POST",
                            url: `${NODE_API_URL}/ccmode/fetchCCDiamondCount`,
                            headers: {
                                "Access-Control-Allow-Origin": "*",
                                "Content-Type": "application/json",
                                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                              },
                            data: {
                                ...location.state.diamondparams,
                                diamond_type:location.state.diamondparams["fancy_color_diamond"] === "YES"?location.state.diamondparams.diamond_type+"F":location.state.diamondparams.diamond_type
                            },
                        }).then(countres => {
                            if(countres && countres.data){
                                if(countres.data.message === "UnAuthorized"){
                                    localStorage.clear();
                                    window.location.reload(true)
                                  }
                                setCount(countres.data.diamondcount)
                            }
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
        setData(location.state.diamonddata)
        setStoneParams(location.state.diamondparams)
        if(location.state.diamondparams && location.state.diamondparams.sort_field && location.state.diamondparams.sort_order){
            setSorting(`${location.state.diamondparams.sort_field}_${location.state.diamondparams.sort_order}`)
        }
    }, []);
    // const data = diamondsearch.value;
    // console.log("data",data)
    // const MakePopup = ({ values }) =>  values && <PopUp props={values} /> ;
    function Currency(){
        if (localStorage.getItem("user_currency") === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const columns = useMemo(
        () => {
            let columns =[
                
                {
                    Header: props.language.CCMODE_THEME1_LIST_ACTION,
                    // accessor: "action",
                    className: "text-left align-middle text-nowrap",
                    accessor: (d) => 
                        <>
                            <button
                                onClick={()=>{ViewDetail(d)}}
                                className="btn btn-light-primary btn-sm px-6 font-weight-bold mr-3"
                            >
                                {props.language.CCMODE_THEME1_GRID_DETAILBTN}
                            </button>
                            {id === "-1" || id === "-2" || id === "" ? (
                                    <>
                                        <button
                                            onClick={(e) => withoutSession()}
                                            className={`btn font-weight-bold ${d.Shortlisted ? "btn-info" : "btn-light-primary"}`}
                                        >
                                            {d.Shortlisted ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <PopUp ProductId={d.STOCK_ID} WLDiamondType={d.diamond_type} C_Weight={d.CARAT} C_Fluorescence={d.FLUORESCENCE} Symmetry={d.SYMMETRY} C_Polish={d.POLISH} C_Cut={d.CUT} C_Clarity={d.CLARITY} C_Color={d.COLOR} C_Shape={d.SHAPE}  CERTIFICATE_NO={d.CERTIFICATE_NO} OurRate={d.OurRate} OurPrice={d.OurPrice} MarkUpRate={d.PRICE_PER_CTS} MarkUpPrice={d.TOTAL_PRICE} Shortlisted={d.Shortlisted} calculateprice={d.calculateprice} PRICE_PER_CTS={d.PRICE_PER_CTS} TOTAL_PRICE={d.TOTAL_PRICE} CURRENCY_RATE={d.CURRENCY_RATE} CURRENCY={d.CURRENCY} diamond_type={d.diamond_type} STOCK_ID={d.STOCK_ID} data={data} setData={setData} taxvalue={d.taxvalue} language={props.language} original={d} {...d}/>
                                        {/* <MakePopup values={d} /> */}
                                    </>
                                )}
                        </>
                       
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_MEDIA,
                    accessor: (d) => (
                        <div className="d-flex">
                            <span>
                                {!d.IMAGE ? (
                                    <span style={{ width: "14px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ) : (
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip">Image</Tooltip>
                                        }
                                    >
                                        {/* <a
                                            target="_blank"
                                            rel="noreferrer"
                                            className="text-primary mx-2 text-left"
                                            href={d.IMAGE}
                                        > */}
                                            {/* <BsCardImage /> */}
                                        <ImagePopup Image={d.IMAGE} Shape={d.SHAPE} />
                                        {/* </a> */}
                                    </OverlayTrigger>
    
                                )}
                            </span>
                            <span>
                                {!d.VIDEO ? (
                                    <span style={{ width: "14px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ) : (
                                    <>
                                        <OverlayTrigger
                                            placement="left"
                                            overlay={
                                                <Tooltip id="tooltip">Video</Tooltip>
                                            }
                                        >
                                            <VideoPopup ProductId={d.STOCK_ID} WLDiamondType={d.diamond_type} CERTIFICATE_NO={d.CERTIFICATE_NO} Video={d.VIDEO}/>
                                        </OverlayTrigger>
                                    </>
                                )}
                            </span>
                            <span>
                                {!d.CERTIFICATE_NO ? (
                                    <span style={{ width: "14px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ) :
                                hideCertificateNumber ? (
                                    <span style={{ width: "14px" }}>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </span>
                                ) : (
                                    <OverlayTrigger
                                        placement="left"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Certificate
                                            </Tooltip>
                                        }
                                    >
                                        
                                        <CertificateLink lab={d.LAB} certificate={d.CERTIFICATE_NO} useIcon className={`text-primary mx-2 text-left`} />
                                    </OverlayTrigger>
                                )}
                            </span>
                        </div>
                    ),
                    className: "text-center align-middle text-nowrap",
                    disableSortBy: true,
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_SHAPE,
                    accessor: "SHAPE",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_COLOR,
                    // accessor: "C_Color",
                    sortMethod: (a, b) => a.localeCompare(b),
                    accessor: (d) => (
                        <>
                            <span>
                                {d.FANCY_COLOR ? (
                                    <>
                                        {d.FANCY_INTENSITY} {d.FANCY_OVERTONE} {d.FANCY_COLOR}
                                    </>
                                ) : (
                                    d.COLOR
                                )}
                            </span>
                        </>
                    ),
                    minwidth: 50,
                    className: "text-center align-middle text-nowrap",
                    Cell: (row) => {
                        return (
                            <>
                                <OverlayTrigger
                                    placement="left"
                                    overlay={
                                        <Tooltip id="tooltip">{row.value}</Tooltip>
                                    }
                                >
                                    <span
                                        className="d-inline-block text-truncate text-dark"
                                        style={{ maxWidth: "80px" }}
                                    >
                                        {row.value}
                                    </span>
                                </OverlayTrigger>
                            </>
                        );
                    },
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_CARAT,
                    className: "text-center align-middle text-nowrap",
                    accessor: (d) => Number(d.CARAT).toFixed(2),
                    sortMethod: (a, b) => Number(a) - Number(b),
                    Cell: (row) => {
                        return <span>{row.value}</span>;
                    },
                },
    
                {
                    Header: props.language.CCMODE_THEME1_LIST_CLARITY,
                    accessor: "CLARITY",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_CUT,
                    accessor: "CUT",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_POLISH,
                    accessor: "POLISH",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_SYMMETRY,
                    accessor: "SYMMETRY",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_FLUORESCENCE,
                    accessor: "FLUORESCENCE",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_LAB,
                    accessor: "LAB",
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header:  `${localStorage.getItem("user_currency")} /CT`,
                    className: "text-left pr-5 align-middle total text-nowrap",
                    accessor: d => Number(d.PRICE_PER_CTS),
                    sortMethod: (a, b) => Number(a)-Number(b),
                    Cell: (row) => {
                        return  <span className="d-flex">{Currency() } {numberWithCommas(row.value)}</span>;
                      },
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_TOTALPRICE,
                    className: "text-right pr-5 text-nowrap align-middle total",
                    accessor: d => Number(d.TOTAL_PRICE),
                    sortMethod: (a, b) => Number(a)-Number(b),
                    Cell: (row) => {
                        return  <span className="d-flex">{Currency() }{numberWithCommas(row.value)}</span>;
                      },
                },
                {
                    Header: props.language.CCMODE_THEME1_LIST_MEASUREMENT,
                    // accessor: "C_Length",
                    accessor: (d) => (<span className="d-flex">{Number(d.LENGTH).toFixed(2)} x {Number(d.WIDTH).toFixed(2)} x {Number(d.DEPTH).toFixed(2)} </span>),
                    className: "text-center align-middle text-nowrap",
                },
                {
                    Header: `${props.language.CCMODE_THEME1__LIST_TABLE} %`,
                    accessor: "TABLE_PER",
                    className: "text-center align-middle text-nowrap",
                    Cell: (row) => {
                        return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}%</> : "-"}</>;
                    },
                },
                {
                    Header: `${props.language.CCMODE_THEME1__LIST_DEPTH} %`,
                    accessor: "DEPTH_PER",
                    className: "text-center align-middle text-nowrap",
                    Cell: (row) => {
                        return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}%</> : "-"}</>;
                    },
                },
                {
                    Header: props.language.CCMODE_THEME1__LIST_RATIO,
                    accessor: "RATIO",
                    className: "text-center align-middle text-nowrap",
                    Cell: (row) => {
                        return <>{row.value ? <>{parseFloat(row.value).toFixed(2)}</> : "-"}</>;
                    },
                },
                {
                    Header: props.language.CCMODE_THEME1__LIST_DELIVEREDIN,
                    accessor: "Shipping_Days",
                    className: "text-center align-middle text-nowrap",
                    Cell: (row) => {
                        return <>{row.value} {props.language.CCMODE_THEME1__LIST_DAYS} </>;
                    },
                },
            ]
            if(hidePrice){
                columns.splice(12, 2);
            }
            if(hideEstimatedShippingDays){
                columns.pop()
            }
            return  columns
        }, [data,hideCertificateNumber,hidePrice,hideEstimatedShippingDays]
    );

    const natGrid = () => {
        history.push({
            pathname: "grid",
            state: {
                diamonddata:[...data.slice(0,100)],
                diamondparams:location.state.diamondparams
            },
            // state: decompress(res.data.ProductDetails),
        });
    };

    const backBtn = () => {
        history.push("/searchdiamond",JSON.parse(localStorage.getItem("themesearch")))
    };
    const nextListPage = () => {
        if(stoneParams){
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page + 1,currency:localStorage.getItem("user_currency")
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page + 1,currency:localStorage.getItem("user_currency")
                        })
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page + 1,currency:localStorage.getItem("user_currency")
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page + 1,currency:localStorage.getItem("user_currency")
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
    }
    const previousListPage = () => {
        if(stoneParams && stoneParams.page - 1){
            if(stoneParams.diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page - 1,currency:localStorage.getItem("user_currency")
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page - 1,currency:localStorage.getItem("user_currency")
                        })
                    }
                    else{
    
                    }
                }).catch(err => {
                    
                })
            }
            if(stoneParams.diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {
                        ...stoneParams,
                        page:stoneParams.page - 1,currency:localStorage.getItem("user_currency")
                    },
                }).then(res => {
                    //setinfiniteLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setData(res.data.data)
                        setStoneParams({
                            ...stoneParams,
                            page:stoneParams.page - 1,currency:localStorage.getItem("user_currency")
                        })
                    }
                    else{
                        
                    }
                }).catch(err => {
                    
                })
            }
        }
    }
    useEffect(() => {
        // console.log(sorting,"sortingsorting")
        if(sorting){
            window.scrollTo(0, 0)
            if(stoneParams){
                let SortingStoneParams = {...stoneParams}
                SortingStoneParams["page"] = 1
                if(sorting === "carat_desc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "carat_asc"){
                    SortingStoneParams["sort_field"] = "carat"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "price_desc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "price_asc"){
                    SortingStoneParams["sort_field"] = "price"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "clarity_desc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "clarity_asc"){
                    SortingStoneParams["sort_field"] = "clarity"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(sorting === "color_desc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "desc"
                }
                if(sorting === "color_asc"){
                    SortingStoneParams["sort_field"] = "color"
                    SortingStoneParams["sort_order"] = "asc"
                }
                if(stoneParams.diamond_type === "N"){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                          },
                        data: {...SortingStoneParams,currency:localStorage.getItem("user_currency")},
                    }).then(res => {
                        // setinfiniteLoader(false)
                        if(res && res.data && res.data.success){
                            if(res.data.message === "UnAuthorized"){
                                localStorage.clear();
                                window.location.reload(true)
                              }
                            history.push({
                                pathname: "/search-result",
                                state: {
                                    diamonddata:res.data.data,
                                    diamondparams:SortingStoneParams
                                },
                                // state: decompress(res.data.ProductDetails),
                            });
                            setStoneParams(SortingStoneParams)
                            setData([
                                ...res.data.data
                            ])
                        }
                        else{
        
                        }
                    }).catch(err => {
                        
                    })
                }
                if(stoneParams.diamond_type === "L"){
                    axios({
                        method: "POST",
                        url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                          },
                        data: {...SortingStoneParams,currency:localStorage.getItem("user_currency")},
                    }).then(res => {
                        // setinfiniteLoader(false)
                        if(res && res.data && res.data.success){
                            if(res.data.message === "UnAuthorized"){
                                localStorage.clear();
                                window.location.reload(true)
                              }
                            history.push({
                                pathname: "/search-result",
                                state: {
                                    diamonddata:res.data.data,
                                    diamondparams:SortingStoneParams
                                },
                                // state: decompress(res.data.ProductDetails),
                            });
                            setStoneParams(SortingStoneParams)
                            setData([
                                ...res.data.data
                            ])
                        }
                        else{
                            
                        }
                    }).catch(err => {
                        
                    })
                }
            }
        }
    },[sorting])
    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        // nextPage,
        // previousPage,
        canPreviousPage,
        canNextPage,
        // pageOptions,
        gotoPage,
        pageCount,
        setPageSize,
        // setGlobalFilter,
        // allColumns,
        // getToggleHideAllColumnsProps,
        state: { pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 100 }
        },
        useGlobalFilter,
        // useSortBy,
        usePagination
    );
        // console.log(pageSize,"pageSize")
    if(user === undefined ){
        return
    }
    
    return (
        <>
            <div className={`container-fluid theme1 ${ data.length >0 && data[0].diamond_type === "L" ? "labTab" :  data.length >0 && data[0].diamond_type === "N" ? "natTab" : ""} `}> 
                <div className="card card-custom card-sticky gutter-b">
                <div className="card-header bg-white d-flex justify-content-between p-2 custom-z-index  align-items-center">
                        <div className="card-title m-0 d-flex align-items-center">
                            {/* <h3 className="font-weight-bolder">{props.language.CCMODE_THEME1_GRID_STOCKLIST}</h3> */}
                            <span
                                className="cursor-pointer text-hover-primary small my-3 text-nowrap"
                                onClick={backBtn}
                            >
                                <FaLongArrowAltLeft /> {props.language.CCMODE_THEME1_GRID_GOTOSEARCHBTN}
                            </span>
                        </div>
                        <div className="card-toolbar flex-md-nowrap">
                            <div className="custom-result-box py-3 mr-3">
                                {props.language.TOTAL_STONE_COUNT}: <span className="font-weight-bold">{count}</span>
                            </div>
                        {/* <SortingTheme1 setSorting={setSorting} sorting={sorting}/> */}
                            <button
                                className="btn btn-primary mr-3 text-nowrap"
                                onClick={natGrid}
                            >
                                <BsFillGridFill /> {props.language.CCMODE_THEME1_LIST_GRIDBTN}
                            </button>
                            <SortingTheme1 setLoader={setLoader} setSorting={setSorting} sorting={sorting} language={props.language} hidePrice={hidePrice}/>
                        </div>
                    </div>
                    <div className="card-body pt-7 pb-0 mt-n3">
                        <div className="table-responsive w-100">
                            <table
                                className="table table-hover custom_datatable"
                                {...getTableProps()}
                            >
                                <thead className="custom_datatable_head">
                                    {headerGroups.map((headerGroup) => (
                                        <tr
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(
                                                (column) => (
                                                    <th
                                                        className="text-center align-middle text-nowrap"
                                                    >
                                                        {column.render(
                                                            "Header"
                                                        )}
                                                        {/* <span className="text-secondary">
                                                            {column.isSorted ? (
                                                                column.isSortedDesc ? (
                                                                    <FaSortDown />
                                                                ) : (
                                                                    <FaSortUp />
                                                                )
                                                            ) : (
                                                                ""
                                                            )}
                                                        </span> */}
                                                    </th>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <>
                                                <tr
                                                    {...row.getRowProps()}
                                                    key={row.id}
                                                >
                                                    {row.cells.map(
                                                        (cell, i) => {
                                                            return (
                                                                <td
                                                                    className="text-left align-middle"
                                                                    {...cell.getCellProps(
                                                                        {
                                                                            className:
                                                                                cell
                                                                                    .column
                                                                                    .className,
                                                                        }
                                                                    )}
                                                                    key={i}
                                                                >
                                                                    {cell.render(
                                                                        "Cell"
                                                                    )}
                                                                </td>
                                                            );
                                                        }
                                                    )}
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-footer border-0">
                        <div className="d-flex align-items-center justify-content-between">
                            <span className="table_page_length">
                                {props.language.CCMODE_THEME3_SEARCHDIAMOND_PAGE} {stoneParams?stoneParams.page:1}
                            </span>
                            <div>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                >
                                    <FaAngleDoubleLeft />
                                </button>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => previousListPage()}
                                    // disabled={!canPreviousPage}
                                >
                                    <FaAngleLeft />
                                </button>
                                <span className="font-weight-bold text-muted">
                                    {"  "}
                                    <input
                                        className="form-control d-inline-block form-control-sm"
                                        type="number"
                                        value={stoneParams?stoneParams.page:1}
                                        onChange={(e) => {
                                            const pageNumber = e.target.value
                                                ? Number(e.target.value) - 1
                                                : 0;
                                            gotoPage(pageNumber);
                                        }}
                                        disabled={true}
                                        style={{ maxWidth: "70px" }}
                                    />
                                    {"  "}
                                    {"  "}
                                </span>
                                <button
                                    className="btn btn-sm btn-light mr-2"
                                    onClick={() => nextListPage()}
                                    // disabled={!canNextPage}
                                >
                                    <FaAngleRight />
                                </button>
                                <button
                                    className="btn btn-sm btn-light"
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                >
                                    <FaAngleDoubleRight />
                                </button>
                            </div>
                            <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">
                                    {props.language.CCMODE_THEME3_SEARCHDIAMOND_ROWSPERPAGE}:{" "}
                                </span>
                                <select
                                    className="form-select ml-2 custom-select py-1"
                                    value={pageSize}
                                    onChange={(e) =>
                                        setPageSize(Number(e.target.value))
                                    }
                                >
                                    {[100].map((pageSize) => (
                                        <option key={pageSize} value={pageSize}>
                                            {pageSize}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
           
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message="You must start a session before adding stones to your cart"
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message="Your stone is added to cart"
                />
            </Snackbar>
        </>
    );
};
