/* eslint-disable jsx-a11y/anchor-is-valid */
import React,{useContext,useState,useEffect,useRef} from "react";
import { useHistory,useLocation } from "react-router-dom";
import { Modal, } from "react-bootstrap";
import { Formik, Form } from "formik";
// import { StoreDiamondDB } from "../../../_helpers/IndexedDB";
// import { BasicLab } from "../../searchDiamComponent/BasicLab";
import { Basic } from "./searchComponentNew/Basic";
import { Advanced } from "./searchComponentNew/Advanced";

import PropTypes from "prop-types";
import axios from "axios";
import {NODE_API_URL} from "../../../../env_config"
import  DiamondContext  from "./SearchDiamondContext";
import {
    makeStyles,
    Snackbar,
    IconButton,
    SnackbarContent,
    Backdrop,
    CircularProgress,
    Paper,
    Tabs,
    Tab,
    Typography
} from "@material-ui/core";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import green from "material-ui/colors/green";
import red from "material-ui/colors/red";
import { SelectSessionModal } from "../../selectSession/selectSession"


function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export const SearchDiamondTheme1 = ({ruleData,naturalData,naturalFancyData,labData,labFancyData,language}) => {
    // console.log(naturalFancyData)
    const formikRef = useRef();
    const diamondsearch = useContext(DiamondContext)
    const location = useLocation()
    const [sessModalShow, setSessModalShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertMessage,setAlertMessage] = useState("")
    const [openLoader, setOpenLoader] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [zeroSuccessOpen, setZeroSuccessOpen] = useState(false);
    const [searchdata,setSearchData] = useState([])
    const [diamond_type,setDiamondType] = useState()
    const [custompage,setCustomPage] = useState(1)
    const [loader,setLoader] = useState(false)
    const [sorting,setSorting] = useState("")
    const [fancy,setFancy] = useState(naturalData.length===0 && naturalFancyData.length !== 0 ? true : false)
    const [fancyLab,setFancyLab] = useState(labData.length===0 && labFancyData.length !== 0 ? true : false)
    useEffect(() => {
        if(location.state && location.state.FinalObject){
            if(location.state.diamond_type === "N"){
                setDiamondType(location.state.diamond_type)
                if(location.state.FinalObject.fancy_color_diamond){
                    setFancy(true)
                }
                else{
                    setFancy(false)
                }
            }
            if(location.state.diamond_type === "L"){
                setDiamondType(location.state.diamond_type)
                if(location.state.FinalObject.fancy_color_diamond){
                    setFancyLab(true)
                }
                else{
                    setFancyLab(false)
                }
            }
        }
        if(location.state && location.state.sorting){
            setSorting(location.state.sorting)        
        }
       },[location.state])
    const [user, setUser] = useState([]);
    const [value, setValue] = useState(window.glob||0);
    const [natData, setNatData] = useState([]);
    const [stoneCount, setStoneCount] = useState([]);
    const [loading, setLoading] = useState(false);
    const variantIcon = {
        success: CheckCircleIcon,
        error: ErrorIcon,
        warning: ErrorIcon,
    };
    const useStyles = makeStyles((theme) => ({
        close: {
            padding: theme.spacing(0.5),
        },
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: red[600],
        },
    }));

    function MySnackbarContentWrapper(props) {
        const classes = useStyles();
        const { className, message, onClose, variant, ...other } = props;
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <Icon className={clsx(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={onClose}
                    >
                        <CloseIcon className={classes.icon} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }

    function handleClose(event, reason) {
        setOpen(false);
        setSessModalShow(false);
        setZeroSuccessOpen(false);
    }

    const enableLoading = () => {
        setLoading(true);
        setOpenLoader(!openLoader);
    };

    const disableLoading = () => {
        setLoading(false);
        setOpenLoader(false);
    };

    MySnackbarContentWrapper.propTypes = {
        className: PropTypes.string,
        message: PropTypes.node,
        onClose: PropTypes.func,
        variant: PropTypes.oneOf(["success", "warning", "error", "info"])
            .isRequired,
    };


    const history = useHistory();
    // const { user } = useSelector((state) => state.auth);


    const id = React.useMemo(()=>localStorage.getItem("C_id"),[])
    // React.useEffect(() => {
    //     const cid = localStorage.getItem("C_id");
    //     setID(cid);
    // }, []);

    // const handleClose = () => setSessModalShow(false);
    // const handleShow = () => setSessModalShow(true);
    function handelSession(){
        setSessModalShow(true)
    }
    
    React.useEffect(() =>{
        
   
    const userData = localStorage.getItem("userData");
        setUser(JSON.parse(userData))
        if(value===0){
            // setDiamondType("N")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }
        }
        if(value===1){
            // setDiamondType("L")
            if((naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0)){
                setDiamondType("N")
            }
            else if((labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)){
                setDiamondType("L")
            }
        }
        if (id === "-2" ){
            setTimeout(() => {
                    handelSession()
            }, 500);
        }
    }, [id]);

    function handleChange(event, newValue) {
        window.glob = newValue
        setDiamondType(newValue);
    }
    function handelStoneCountError(){
        setOpen(true)  
        disableLoading() 
    }
    function handelStoneCountWarn(){
        setZeroSuccessOpen(true)  
        disableLoading() 
    }
    function handelRest(newValue){
        formikRef.current?.resetForm()
        window.history.replaceState({}, '') 
        window.location.reload(false);
    }
    // natural ---------------------------------------------
    function handleSearch(values) {
        let FinalObject = {
            page:custompage,
            user_id:user.CustomerId,
            diamond_type:diamond_type,
            ConsumerId:id,
            currency:localStorage.getItem("user_currency")
        }
        
        // console.log(values,"values")

        if(values.Shape && values.Shape.length){
            FinalObject["shape"] = values.Shape
        }
        if(values.Cut && values.Cut.length){
            FinalObject["cut"] = values.Cut
        }
        if(values.Clarity && values.Clarity.length){
            FinalObject["clarity"] = values.Clarity
        }
        if(values.Color && values.Color.length){
            FinalObject["color"] = values.Color
        }
        if(fancy && diamond_type === "N"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(fancyLab && diamond_type === "L"){
            FinalObject["fancy_color_diamond"] = "YES"
        }
        if(values.Fancy_Color && values.Fancy_Color.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_color"] = values.Fancy_Color
        }
        if(values.Fancy_Color_Overtone && values.Fancy_Color_Overtone.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_overtone"] = values.Fancy_Color_Overtone
        }
        if(values.Fancy_Color_Intensity && values.Fancy_Color_Intensity.length){
            //FinalObject["fancy_color_diamond"] = "YES"
            FinalObject["fancy_intensity"] = values.Fancy_Color_Intensity
        }
        if(values.Lab && values.Lab.length){
            FinalObject["lab"] = values.Lab
        }
        if(values.Polish && values.Polish.length){
            FinalObject["polish"] = values.Polish
        }
        if(values.Symmetry && values.Symmetry.length){
            FinalObject["symmetry"] = values.Symmetry
        }
        if(values.Fluorescence_Intensity && values.Fluorescence_Intensity.length){
            FinalObject["fluorescence"] = values.Fluorescence_Intensity
        }
        if(values.minCarat >= 0){
            FinalObject["min_carat"] = values.minCarat
        }
        if(values.maxCarat >= 0){
            FinalObject["max_carat"] = values.maxCarat
        }
        if(values.minPrice >= 0){
            FinalObject["total_price_from"] = values.minPrice
        }
        if(values.maxPrice >= 0){
            FinalObject["total_price_to"] = values.maxPrice
        }

        if(values.minDepth >= 0){
            FinalObject["depthmin"] = values.minDepth
        }
        if(values.maxDepth >= 0){
            FinalObject["depthmax"] = values.maxDepth
        }
        if(values.minTable >= 0){
            FinalObject["tablemin"] = values.minTable
        }
        if(values.maxTable >= 0){
            FinalObject["tablemax"] = values.maxTable
        }
        if(values.minRatio >= 0){
            FinalObject["ratiomin"] = values.minRatio
        }
        if(values.maxRatio >= 0){
            FinalObject["ratiomax"] = values.maxRatio
        }
        if(sorting === "carat_desc"){
            FinalObject["caratfilter"] = "dsc"
        }
        if(sorting === "carat_asc"){
            FinalObject["caratfilter"] = "asc"
        }
        if(sorting === "price_desc"){
            FinalObject["pricefilter"] = "dsc"
        }
        if(sorting === "price_asc"){
            FinalObject["pricefilter"] = "asc"
        }
        if(sorting === "clarity_desc"){
            FinalObject["clarityfilter"] = "dsc"
        }
        if(sorting === "clarity_asc"){
            FinalObject["clarityfilter"] = "asc"
        }
        if(sorting === "color_desc"){
            FinalObject["colorfilter"] = "dsc"
        }
        if(sorting === "color_asc"){
            FinalObject["colorfilter"] = "asc"
        }
        if(values.Min_Carat >= 0){
            FinalObject["min_carat"] = values.Min_Carat
        }
        if(values.Max_Carat >= 0){
            FinalObject["max_carat"] = values.Max_Carat
        }
        if(values.Min_Price >= 0){
            FinalObject["total_price_from"] = values.Min_Price
        }
        if(values.Max_Price >= 0){
            FinalObject["total_price_to"] = values.Max_Price
        }
        if(values.Min_Table_Prc >= 0){
            FinalObject["tablemin"] = values.Min_Table_Prc
        }
        if(values.Max_Table_Prc >= 0){
            FinalObject["tablemax"] = values.Max_Table_Prc
        }
        if(values.Min_Depth_Prc >= 0){
            FinalObject["depthmin"] = values.Min_Depth_Prc
        }
        if(values.Max_Depth_Prc >= 0){
            FinalObject["depthmax"] = values.Max_Depth_Prc
        }
        if(values.Min_Ratio >= 0){
            FinalObject["ratiomin"] = values.Min_Ratio
        }
        if(values.Max_Ratio >= 0){
            FinalObject["ratiomax"] = values.Max_Ratio
        }
        setLoader(true)
        let len = Object.keys(FinalObject).length;
            const temp = {...FinalObject};
            let data = "";
            for (let key in temp) {
                len--;
                let arr = temp[key];
                if (len) {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" }, `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"},`;
                    }
                } else {
                    if (Array.isArray(arr)) {
                        let str = "";
                        str += "'" + arr.join("','") + "'";
                        data += `{"FilterName": "${key}","FilterValue":"${str}" } `;
                    } else {
                        data += `{"FilterName": "${key}","FilterValue": "'${temp[key]}'"}`;
                    }
                }
            }
            // const finalData = `{ "JsonData":  [${data}] , "CustomerId": ${user.CustomerId}, "ConsumerId":${id=== "" ? "-1" : id}, "WLDiamondType": "${diamond_type}" }`;
            // console.log(finalData,"finalData")
            // axios({
            //     method: "POST",
            //     url: SEARCH_LOG,
            //     headers: { "Content-Type": "application/json" },
            //     data: finalData,
            // })
            //     .then(function(res) {
            //         // console.log(res);
            //     })
            //     .catch(function(res) {
            //         console.log(res);
            //     });
                let LogObj = {...FinalObject,diamond_type:diamond_type}
                delete LogObj.token
                if(LogObj.fancy_color_diamond === "YES"){
                    delete LogObj.color
                }else{
                    delete LogObj.fancy_color
                    delete LogObj.fancy_intensity
                    delete LogObj.fancy_intensity
                }
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: {
                        user_id:FinalObject.user_id,
                        activity_type:"Ccmode Search",
                        user_agent:navigator.userAgent,
                        activity_data:LogObj,
                        ip_address:localStorage.getItem("ipData"),
                        consumer_id:id,
                        diamond_type:diamond_type
                    }
                }).then(res => {
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear();
                        window.location.reload(true)
                      }
                }).catch(error => {
                })    
            if(diamond_type === "N"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeNaturalStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setSearchData(res.data)
                        setStoneCount(JSON.stringify(res.data.total));
                        history.push({
                            pathname: "/grid",
                            state: {
                                diamonddata:res.data.data,
                                diamondparams:FinalObject
                            },
                            // state: decompress(res.data.ProductDetails),
                        });
                        //StoreDiamondDB([...res.data.data])
                    }
                    else{
                        setOpen(true)
                        if(res.data.message){
                            setAlertMessage(res.data.message)
                        }  
                        disableLoading()
                    }
                }).catch(err => {
                    setLoader(false)
                })
            }
            if(diamond_type === "L"){
                axios({
                    method: "POST",
                    url: `${NODE_API_URL}/ccmode/fetchCCModeLabStones`,
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                      },
                    data: FinalObject,
                }).then(res => {
                    setLoader(false)
                    if(res && res.data && res.data.success){
                        if(res.data.message === "UnAuthorized"){
                            localStorage.clear();
                            window.location.reload(true)
                          }
                        setSearchData(res.data)
                        setStoneCount(JSON.stringify(res.data.total));
                        history.push({
                            pathname: "/grid",
                            state: {
                                diamonddata:res.data.data,
                                diamondparams:FinalObject
                            },
                            // state: decompress(res.data.ProductDetails),
                        });
                        //StoreDiamondDB([...res.data.data])
                    }
                    else{
                        setOpen(true)  
                        if(res.data.message){
                            setAlertMessage(res.data.message)
                        }
                        disableLoading()
                    }
                }).catch(err => {
                    setLoader(false)
                })
            }
            localStorage.setItem("themesearch",JSON.stringify({Formik:values,FinalObject:FinalObject,diamond_type:diamond_type,sorting:sorting}))
    }

    const [finalFetchData, setFinalFetchData] = React.useState([]);
    React.useEffect(
        () => window.localStorage.setItem("fetchData", finalFetchData),
        [finalFetchData]
    );
    React.useEffect(
        () => window.localStorage.setItem("stoneCount", stoneCount),
        [stoneCount]
    );
    const ResetFormTab = () => {
        formikRef.current?.resetForm()
        window.history.replaceState({}, '')
    }
    return (
    (user? 
        <div className="container-fluid theme1">
            <div className={`row ${ diamond_type === "L" ? "labTab" :  diamond_type === "N" ? "natTab" : ""}`}>
                <div className="col-12">
                    <div className="card card-custom card-sticky gutter-b">
                        <div
                            className="card-header minHeight justify-content-start p-0"
                            style={{
                                height: " 49px!important",
                            }}
                        >
                            <Paper position="static">
                            <Tabs value={diamond_type} onChange={handleChange}>
                                    {(naturalData.length !== 0 && naturalData[0].status !== 0) || (naturalFancyData.length !== 0 && naturalFancyData[0].status !== 0) ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_NATURAL} value="N" onClick={() => ResetFormTab()}/>:""}
                                    {(labData.length !== 0 && labData[0].status !== 0)|| (labFancyData.length !== 0 && labFancyData[0].status !== 0)  ? <Tab label={language.CCMODE_THEME1_SEARCHDIAMOND_LABGROWN} value="L" onClick={() => ResetFormTab()}/>:""}
                                </Tabs>
                            </Paper>
                        </div>
                            <Formik
                                innerRef={formikRef}
                                enableReinitialize = {true}
                                initialValues={location.state && location.state.Formik || {}}
                                onSubmit={async (values) => {
                                    enableLoading();
                                    Object.keys(values).forEach(key => {
                                        if (values[key] === '' || values[key] === null || values[key].length === 0) {
                                                 delete values[key];
                                          }
                                        });
                                    setTimeout(() => {
                                        handleSearch(values);
                                    }, 1000);
                                }}
                            >
                                {(formik, isSubmitting, handleSubmit) => (
                                    <>
                                        <Form>
                                            <div className="card-body">
                                                <div className="row">
                                                    {/* data: {finalNaturalData} */}
                                                    <div className="col-12 mb-10">
                                                        <Basic formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} sorting={sorting} ruleData={ruleData} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} setFancy={setFancy} fancy={fancy} setFancyLab={setFancyLab} fancyLab={fancyLab} language={language}/>
                                                    </div>
                                                    {/* <div className="col-12 mb-10">
                                                        <Preference />
                                                    </div> */}
                                                    <div className="col-12 mb-10">
                                                        <Advanced formik={formik} setSearchData={setSearchData} diamond_type={diamond_type} custompage={custompage} setLoader={setLoader} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} fancy={fancy} fancyLab={fancyLab} language={language} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="card-footer natTab text-right border-0 sticky-top"
                                                style={{ bottom: "0" }}
                                            >
                                                
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary px-10 font-weight-bold mr-2 btn-lg"
                                                    disabled={isSubmitting}
                                                    onClick={handleSubmit}
                                                >
                                                    {isSubmitting
                                                        ? language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_PLEASEWAIT
                                                        : language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_SEARCH}
                                                    {loading && (
                                                        <span className="ml-3 spinner spinner-white"></span>
                                                    )}
                                                </button>
                                                <Backdrop
                                                    sx={{ color: '#002173', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                                                    open={openLoader}
                                                    // onClick={handleCloseLoader}
                                                >
                                                    <CircularProgress color="secondary" />
                                                </Backdrop>
                                                <button
                                                    type="reset"
                                                    onClick={handelRest}
                                                    className="btn font-weight-bold btn-light-primary btn-lg px-10"
                                                >
                                                    {language.CCMODE_THEME1_SEARCHDIAMOND_PERCENTAGE_RESET}
                                                </button>
                                                
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                    </div>
                </div>
                <div className="col-12 d-none"></div>
            </div>
            <Modal
                show={sessModalShow}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body >
                    <SelectSessionModal setOpen={setOpen} setSessModalShow={setSessModalShow} language={language}/>
                </Modal.Body>
            </Modal>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="error"
                    message={alertMessage}
                />
            </Snackbar>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={successOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="success"
                    message={`${stoneCount} Stones found`}
                />
            </Snackbar>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={zeroSuccessOpen}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={handleClose}
                    variant="warning"
                    message={`0 Stones found please try again`}
                />
            </Snackbar>
        </div>
    : "")
    );
};
