import React from "react";
import { MenuItem, FormControl, Select, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 110,
    },
   
    menuItemScroll: {
        maxHeight: '200px',
        overflowY: 'auto',
      },
  }));

export default function Sorting(props) {
  const classes = useStyles();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="sorting" >
      {/* <span className="w-100 mr-2">{props.language.CCMODE_THEME1_GRID_SORTBY}: {props.sorting ? capitalizeFirstLetter(props.sorting.split('_')[0]) : ""}</span> */}
      <FormControl className={`
        +${classes.formControl}`}>
        <Select
          MenuProps={{
            classes: {
              paper: classes.menuItemScroll,
            },
          }}
          variant="outlined"
          margin="dense"
          onChange={(e) => props.setSorting(e.target.value)}
          value={props.sorting || ""}
          displayEmpty
        >
          <MenuItem className="sorting-menu" value="" disabled>
            {props.language.CCMODE_THEME1_GRID_SORTBY}
          </MenuItem>
          
          <MenuItem disabled className={classes.optgroup}>Carat</MenuItem>
          <MenuItem className="sorting-menu" value="carat_desc" selected={props.sorting === "carat_desc"}>
            High to Low
          </MenuItem>
          <MenuItem className="sorting-menu" value="carat_asc" selected={props.sorting === "carat_asc"}>
            Low to High
          </MenuItem>

          <MenuItem disabled className={classes.optgroup}>Color</MenuItem>
          <MenuItem className="sorting-menu" value="color_desc" selected={props.sorting === "color_desc"}>
            Z to D
          </MenuItem>
          <MenuItem className="sorting-menu" value="color_asc" selected={props.sorting === "color_asc"}>
            D to Z
          </MenuItem>

          <MenuItem disabled className={classes.optgroup}>Clarity</MenuItem>
          <MenuItem className="sorting-menu" value="clarity_desc" selected={props.sorting === "clarity_desc"}>
            I3 to FL
          </MenuItem>
          <MenuItem className="sorting-menu" value="clarity_asc" selected={props.sorting === "clarity_asc"}>
            FL to I3
          </MenuItem>

          {/* {!props.hidePrice && (
            <>
              <MenuItem disabled className={classes.optgroup}>Price</MenuItem>
              <MenuItem className="sorting-menu" value="price_desc" selected={props.sorting === "price_desc"}>
                High to Low
              </MenuItem>
              <MenuItem className="sorting-menu" value="price_asc" selected={props.sorting === "price_asc"}>
                Low to High
              </MenuItem>
            </>
          )} */}
          {!props.hidePrice && <MenuItem disabled className={classes.optgroup}>Price</MenuItem>}
              {!props.hidePrice && <MenuItem className="sorting-menu" value="price_desc" selected={props.sorting === "price_desc"}>
                High to Low
              </MenuItem>}
              {!props.hidePrice && <MenuItem className="sorting-menu" value="price_asc" selected={props.sorting === "price_asc"}>
                Low to High
              </MenuItem>}
        </Select>
      </FormControl>
    </div>
  );
}
