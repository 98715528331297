import React, {  useState,useMemo } from "react";
import {
    Modal,
    Button,
} from "react-bootstrap";
import axios from "axios";
import {IUD_SHORTLIST, NODE_API_URL} from "./../../../env_config"
import { GetDiamondDB ,StoreDiamondDB} from "../../_helpers/IndexedDB";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateShortlistStoneCount } from "../../../redux/commonRedux/common.actions";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_helpers";
import CertificateLink from "../CertificateLink";

export const PopUp = (props) => {
    const [update,setUpdate] = React.useState(props.Shortlisted);
    const [show, setShow] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const id = useMemo(()=>localStorage.getItem("C_id"),[])
    const userCurrency = useMemo(()=>localStorage.getItem("userCurrency"),[])
    const usercur = JSON.parse(userCurrency)
    
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    function handleShow(data) {
        setShow(true);
        if(!update){
            handleShortlist(data)
        }
    }
    const [diamondsearch,setDiamondSearch] = useState([])
    const dispatch = useDispatch();
    const { hidePrice, hideCertificateNumber } = useSelector(
      (state) => state.CcModeSettingReducer
    );

    const shortListedStoneCount = useSelector((state) => {
      return state.ShortlistStoneReducer?.shortlistStoneCount;
    });

    useEffect(() => {
        GetDiamondDB().then(response => {
            setDiamondSearch(response)
        })
    },[diamondsearch])
        // Get device information
        function detectDevice() {
            // Check if the user agent contains the substring "Mobi"
            return /Mobi/.test(navigator.userAgent) ? "Mobile" : "Pc";
        }
    
        function detectOS() {
            const userAgent = navigator.userAgent;
            if (/Windows/.test(userAgent)) {
                return "Windows";
            } else if (/Macintosh/.test(userAgent)) {
                return "Mac OS";
            } else if (/Linux/.test(userAgent)) {
                return "Linux";
            } else if (/Android/.test(userAgent)) {
                return "Android";
            } else if (/iPhone|iPad|iPod/.test(userAgent)) {
                return "iOS";
            } else {
                return "Unknown";
            }
        }

    function handleShortlist(props) {

        const deviceType = detectDevice();
        const os = detectOS();

        const deviceInfo = `${deviceType} - ${os}`; // Combine device type and OS into a single field

        // console.log(props.CertificateNo)
        // console.log(props,"propsprops")
        // const newdiamondata = diamondsearch.map(value => {
        //     if(value.ProductId === props.ProductId){
        //         value.Shortlisted = 1
        //     }
        //     return value
        // })
        // console.log(newdiamondata,"newdiamondata")
        // StoreDiamondDB(newdiamondata)
        const values = JSON.stringify({
            Tag: "I",
            Id: 0,
            CustomerId: user.CustomerId,
            ConsumerId: id,
            ProductId: props.STOCK_ID,
            CertiNo: props.CERTIFICATE_NO,
            WLDiamondType: props.diamond_type,
            Currency: props.CURRENCY,
            CurrencyConversionRate: props.CURRENCY_RATE,
            MarkUpNatural: 0,
            MarkUpNaturalFancy: 0,
            MarkUpLab: 0,
            MarkUpLabFancy: 0,
            OurRate: props.calculateprice?props.calculateprice.T_C_Rate:0,
            OurPrice: props.calculateprice?props.calculateprice.total_our_price:0,
            MarkUpRate: Math.round(props.markupdollpercar * 100)/100,
            MarkUpPrice: Math.round(props.markupprice * 100)/100,
            // PRICE_PER_CTS: props.PRICE_PER_CTS,
            // TOTAL_PRICE: props.TOTAL_PRICE,
            CreatedAt: "0000-00-00 00:00:00",
            Tax: props.taxvalue,
            ip:localStorage.getItem("ipData"),
            device:deviceInfo,
        });
        // console.log(values);
        axios({
            method: "POST",
            url: `${NODE_API_URL}/ccmode/shortlistStone`,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
            data: values,
        })
            .then(function(res) {
                if(res.data.message === "UnAuthorized"){
                    localStorage.clear();
                    window.location.reload(true)
                  }
                // console.log(res.data);
                // console.log("success",values);
                let diamond_details = {
                    // "ProductId":props.STOCK_ID,
                    // "ListName":"Test",
                    // "ConsumerName":"pancham",
                    // "EMail":"Kumarpancham8873@gmail.com",
                    // "ContactNo":"7004896788",
                    // "Notes":"Test Mode",
                    "STOCK_ID": props.original.STOCK_ID,
                    "Shipping_Days": props.original.Shipping_Days,
                    "SHAPE": props.original.SHAPE,
                    "CARAT": props.original.CARAT,
                    "CLARITY": props.original.CLARITY,
                    "CUT": props.original.CUT,
                    "POLISH": props.original.POLISH,
                    "SYMMETRY": props.original.SYMMETRY,
                    "FLUORESCENCE": props.original.FLUORESCENCE,
                    "LAB": props.original.LAB,
                    "CERTIFICATE_LINK": props.original.CERTIFICATE_LINK,
                    "WIDTH": props.original.WIDTH,
                    "LENGTH": props.original.LENGTH,
                    "DEPTH": props.original.DEPTH,
                    "DEPTH_PER": props.original.DEPTH_PER,
                    "TABLE_PER": props.original.TABLE_PER,
                    "CROWNANGLE": props.original.CROWNANGLE,
                    "CROWNHEIGHT": props.original.CROWNHEIGHT,
                    "PAVILIONHEIGHT": props.original.PAVILIONHEIGHT,
                    "PAVILIONANGLE": props.original.PAVILIONANGLE,
                    "CERTIFICATE_NO": props.original.CERTIFICATE_NO,
                    "MARKUP_PRICE_PER_CTS": props.original.PRICE_PER_CTS,
                    "TOTAL_PRICE": props.original.TOTAL_PRICE,
                    "ORIGIN": props.original.ORIGIN,
                    "TREATMENT": props.original.TREATMENT,
                    "BRAND": props.original.BRAND,
                    "SHADE": props.original.SHADE,
                    "MILKY": props.original.MILKY,
                    "EYE_CLEAN": props.original.EYE_CLEAN,
                    "COUNTRY": props.original.COUNTRY,
                    "CURRENCY": props.original.CURRENCY,
                    "CURRENCY_RATE": props.original.CURRENCY_RATE,
                    "CULET": props.original.CULET,
                    "GIRDLE": props.original.GIRDLE,
                    "GIRDLE_CONDITION": props.original.GIRDLE_CONDITION,
                    "KEY_TO_SYMBOL": props.original.KEY_TO_SYMBOL,
                    "RATIO": props.original.RATIO,
                    "IMAGE": props.original.IMAGE,
                    "VIDEO": props.original.VIDEO,
                    "FANCY_INTENSITY": props.original.FANCY_INTENSITY,
                    "FANCY_OVERTONE": props.original.FANCY_OVERTONE,
                    "FANCY_COLOR": props.original.FANCY_COLOR,
                    "COLOR": props.original.COLOR,
                    "diamond_type": props.original.diamond_type,
                    "girdle_thin": props.original.girdle_thin,
                    "Pav_Ag": props.original.Pav_Ag,
                    "Crn_Ag": props.original.Crn_Ag,
                    "calculateprice": props.original.calculateprice.total_our_price,
                    "Shortlisted": props.original.Shortlisted,
                    "taxvalue": props.original.taxvalue,
                }
                // axios({
                //     method: "POST",
                //     url: `${NODE_API_URL}/ccmode/storeUserACtivity`,
                //     headers: { 
                //         "Content-Type": "application/json"
                //     //    "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImlvczIzdXNlckBnbWFpbC5jb20iLCJ1c2VyX2lkIjoyMzk5LCJhcGlfaWQiOjE4NiwiYXBpX3BsYW4iOiJFbnRlcnByaXNlIiwiaWF0IjoxNjkzODk4NjIzfQ.8jot5UnFmUzMide-jvKJ3PbKrPuWGNNGEtvvaUS8y3w`
                //      },
                //     data: {
                //         user_id:user.CustomerId,
                //         activity_type:"Ccmode Shortlist",
                //         user_agent:navigator.userAgent,
                //         activity_data:{
                //             ...diamond_details
                //         },
                //         ip_address:localStorage.getItem("ipData"),
                //         consumer_id:id,
                //         diamond_type:props.diamond_type
                //     }
                // }).then(response => {
                // }).catch(error => {
                // })
                // alert("Stone added successfully");
                setUpdate(1)
                // setSuccessOpen(true);
                // setShow(false)

                dispatch(
                  updateShortlistStoneCount({
                    shortListedStoneCount: shortListedStoneCount + 1,
                  })
                );

                const newdiamondata = props.data.map(value => {
                    if(value.STOCK_ID === props.STOCK_ID){
                        value.Shortlisted = 1
                    }
                    return value
                })
                // console.log(newdiamondata,"newdiamondata")
                if(props.setData){
                    props.setData(newdiamondata)                    
                }
                if(props.setShortlist){
                    props.setShortlist(!props.shortlist)
                }
            })
            .catch(function(res) {
                console.log("error",values);
                // console.log(res);
            });
    }
    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function Currency(){
        if (localStorage.getItem("user_currency") === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }

    return (
        <>
            <button
                onClick={() => handleShow(props)}
                className={`btn font-weight-bold btn-sm ${props.Shortlisted ? "btn-info" : "btn-primary"}`}
            >
                {props.Shortlisted ? props.language.CCMODE_THEME1_GRID_SHORTLISTEDBTN : props.language.CCMODE_THEME1_GRID_SHORTLISTBTN}
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                className={`${props.diamond_type === "N" ? "natTab" : "labTab"}`}
                backdrop="static"
                keyboard={false}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>{props.Shortlisted ? props.language.CCMODE_THEME3_SHORTLISTWARNING: props.language.CCMODE_THEME3_SHORTLISTCONFIRMATION}</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="text-center mb-10"> <h3 className="text-dark font-weight-bold">You have just added</h3></div>
                    <div className="d-flex w-100 mb-4 pb-4 px-4 justify-content-between">
                        <div className="d-flex">
                            <div className="text-center p-0">
                                {props.IMAGE &&  props.IMAGE !=="null" ? (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.SHAPE}
                                        src={props.IMAGE}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/${props.SHAPE && props.SHAPE.toLowerCase()}-no.png`
                                            )
                                        }}
                                        width="145px"
                                    />
                                ) : (
                                    <img
                                        className="img-fluid mr-2"
                                        alt={props.SHAPE}
                                        src={toAbsoluteUrl(
                                            `/media/shape/${props.SHAPE && props.SHAPE.toLowerCase()}-no.png`
                                        )}
                                        onError={e => {
                                            e.currentTarget.src = toAbsoluteUrl(
                                                `/media/shape/noimage.jpg`
                                            )
                                        }}
                                        width="145px"
                                        style={{ backgroundColor: "#f4f4f4" }}
                                    />
                                )}
                            </div>
                            <div className="pl-2 p-0 text-left">
                                <div className={`mb-1 ${props.diamond_type === "N" ? "natTab" : "labTab"}`}>
                                    <span className={`${ props.diamond_type === "N" ? props.FANCY_COLOR === "fancy" ? "F" : "W" : props.FANCY_COLOR === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                        {props.diamond_type === "N" || props.diamond_type === "W" ? props.FANCY_COLOR === "fancy"  ? "NF" : "N" : props.FANCY_COLOR === "fancy" ? "LF" : "L"}
                                    </span>
                                    <span className={`text-primary font-weight-bold small ml-1`}>
                                        {props.diamond_type === "N" ? "N" : "L"}-{props.STOCK_ID}
                                    </span>
                                    {
                                        hideCertificateNumber ?
                                        <span className="text-primary font-weight-bold mx-2 small">{props.Lab}</span> :
                                        <CertificateLink lab={props.LAB} certificate={props.CERTIFICATE_NO}  className={`text-primary font-weight-bold mx-2 small`} />
                                    }                                                            
                                </div>
                                <div className="text-dark font-size-h6 mb-2 font-weight-bold d-block">
                                    {props.SHAPE}{" "}
                                    {parseFloat(props.CARAT).toFixed(2)}{" "}
                                    {props.COLOR === "fancy" ? (
                                        <>
                                            {props.FANCY_COLOR}{" "}
                                        </>
                                    ) : (
                                        <>{props.COLOR}{" "}</>
                                    )}
                                    {props.CLARITY}{" "}{props.CUT}{" "}{props.POLISH}{" "}{props.SYMMETRY}{" "}
                                    {props.FLUORESCENCE}
                                </div>
                                <div >
                                    {
                                        !hidePrice && (                                                            
                                            <div className="d-flex">
                                                <div className="mr-4">
                                                    <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                    <p className="text-dark font-size-h6 font-weight-bold">
                                                        <span>{Currency()}</span>
                                                        {numberWithCommas(props.PRICE_PER_CTS)}
                                                    </p>
                                                </div>
                                                
                                                <div className="">
                                                    <p className="text-muted font-weight-bold small mb-0 text-uppercase">Total</p>
                                                    <p className="text-dark font-size-h6 font-weight-bold">
                                                        <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                        {numberWithCommas(props.TOTAL_PRICE)}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    }                                                                                                        
                                </div>
                                <div>
                                    <button className="btn btn-light-primary" onClick={handleClose}> Close </button>
                                    <Link to={`/shortlist`} className="btn ml-2 btn-primary" > Go to cart </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        {props.Shortlisted ? props.language.CCMODE_THEME3_SHORTLISTOK: props.language.CCMODE_THEME3_SHORTLISTNO}
                    </Button>
                    { (props.Shortlisted) ?
                        (
                            <> </>
                        ) : (
                            <>
                                <Button
                                    variant="primary"
                                    onClick={() =>
                                        handleShortlist(props)
                                    }
                                >
                                    {props.language.CCMODE_THEME3_SHORTLISTYES}
                                </Button>
                            </>
                        )
                    }
                </Modal.Footer> */}
            </Modal>
        </>
    );
};
